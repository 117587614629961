<template>
  <div class="cal">
    <!-- <CalculatorDisplayTable /> -->
    <CalculatorFormCalculator />
  </div>
</template>

<script>
import CalculatorFormCalculator from "../../components/calculator/formCalculator.vue";
export default {
  name: "calculator",
  components: {
    CalculatorFormCalculator,
  },
  methods: {},
};
</script>

<style scoped>
</style>