<template>
  <div class="main" v-if="fetched == true">
    <div class="content" :id="componentKey">
      <div class="cal">
        <form class="calc-form" @submit.prevent="feeCalculate">
          <v-autocomplete
            filled
            required
            dense
            v-model="selectedFrom"
            :items="from"
            item-text="name"
            label="From"
          >
          </v-autocomplete>
          <v-autocomplete
            filled
            dense
            v-model="selectedTo"
            req
            item-text="name"
            return-object
            :items="calcData.countries"
            label="To"
            required
          >
          </v-autocomplete>
          <v-text-field
            filled
            dense
            type="number"
            v-model="weight"
            label="Weight"
            required
            append-outer="5"
            min="10"
            max="30000"
            suffix="grams"
            :messages="calculateWeight"
          >
          </v-text-field>

          <div class="calculate">
            <v-btn
              type="submit"
              tile
              color="#8600D9"
              class="btn-cal justify-center"
            >
              <v-icon left> mdi-calculator-variant </v-icon>
              Calculate
            </v-btn>
          </div>
        </form>
      </div>

      <div class="table" rounded v-if="showTable == true">
        <v-data-table
          :headers="headers"
          :items="Courier"
          :items-per-page="7"
          class="elevation-1 rounded-lg"
        >
          <template v-slot:[`item.img`]="{ item }">
            <img :src="item.img" style="width: 90px; height: 70px" />
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "formCalculator",
  data: () => ({
    // items: ["India", "Australia", "United states", "Srilanka"],
    // exportCalculator: true,
    showTable: false,
    name: "Srilanka",
    email: "",
    select: null,
    componentKey: 0,
    from: ["Srilanka"],
    // to: [],
    selectedFrom: "Srilanka",
    selectedTo: null,
    weight: null,
    currencyCost: null,
    headers: [
      // {
      //   text: "Sno",
      //   align: "start",
      //   sortable: false,
      //   value: "sNo",
      //   class: "purple accent-4 white--text ",
      //   logo: "Images",
      // },
      {
        text: "Courier service provider ",
        value: "img",
        align: "start",
        sortable: false,
        class: "purple accent-4 white--text",
      },
      {
        text: "Courier service name ",
        value: "name",
        sortable: false,
        class: "purple accent-4 white--text",
      },
      {
        text: "Rate(LKR)",
        value: "rate",
        sortable: true,
        class: "purple accent-4 white--text",
      },
      {
        text: "Delivery duration",
        value: "transit",
        sortable: false,
        class: "purple accent-4 white--text",
      },
      {
        text: "Description",
        value: "description",
        sortable: false,
        class: "purple accent-4 white--text",
      },
    ],
    Courier: [],
    fetched: false,
    // calcData: [],
    calculateWeight: null,
  }),
  computed: {
    ...mapGetters(["calcData"]),
  },
  watch: {
    weight(newValue) {
      if (newValue != null && newValue != undefined && newValue != "") {
        if (newValue < 1000) {
          this.calculateWeight = newValue + " grams";
        } else {
          this.calculateWeight = newValue / 1000 + " kg";
        }
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions([
      "fetchCalculationData",
      "fetchallExportServices",
      "caculateExportPrice",
    ]),
    async init() {
      this.selectedFrom = "Srilanka";
      await this.fetchCalculationData()
        .then((success) => {
          console.log(success);
          this.fetched = true;
        })
        .catch((error) => {
          console.error("error", error);
          this.fetched = false;
        });
      await this.fetchallExportServices();
    },
    // importCalculator() {
    //   // this.exportCalculator = false;
    //   this.componentKey = 0;
    //   this.to = ["SriLanka"];
    //   this.from = ["India", "Australia", "United states"];
    // },
    // exportCalculator() {
    //   // this.exportCalculator = true;
    //   this.componentKey = 1;
    //   this.to = ["India", "Australia", "United states"];
    //   this.from = ["SriLanka"];
    // },
    async feeCalculate() {
      this.Courier = [];
      // const info = {
      //   from: this.selectedFrom,
      //   to: this.selectedTo,
      //   weight: this.weight,
      // };
      // console.log("info", info);
      await this.caculateExportPrice({
        courierId: null,
        to: this.selectedTo,
        weight: this.weight,
      })
        .then((result) => {
          console.log("result", result);
          this.Courier = result;
          this.fetched = true;
        })
        .catch((error) => {
          console.error("error", error);
          this.fetched = false;
        });
      this.showTable = true;
    },

    // async fetchCalculationDetails() {
    //   await this.$fire.firestore
    //     .collection("exportServices")
    //     .get()
    //     .then((querySnapShot) => {
    //       querySnapShot.forEach((doc) => {
    //         let data = {};
    //         (data = doc.data()), (data.id = doc.id), console.log("data", data);
    //         this.calcData.push(data);
    //         console.log("this.calcData", this.calcData);
    //       });
    //     })
    //     .catch((error) => {
    //       console.error("error", error);
    //     });
    // },
    // async getCurrencyPrice() {
    //   await this.$fire.firestore
    //     .collection("pages")
    //     .doc("currencyCosts")
    //     .get()
    //     .then((response) => {
    //       this.currencyCost = response.data();
    //       console.log("currencyCost", this.currencyCost);
    //     });
    // },
    // priceCalculate() {
    //   let priceCalculationData = null;
    //   let i = 0;
    //   for (i = 0; i < this.calcData.length; i++) {
    //     const postage = this.calcData[i];
    //     console.log("postage", postage);
    //     const ssp = postage.countries.find((item) => {
    //       return item.code == this.selectedTo.code;
    //     });
    //     console.log("ssp", ssp);
    //     const cc = parseInt(ssp.covidCost);
    //     const pp = parseInt(ssp.pieceCost);
    //     const kgc = parseInt(ssp.kgCost);
    //     const fc = parseInt(ssp.fuelCost);
    //     const com = parseInt(ssp.commissionCost);

    //     const mrlk = parseInt(this.currencyCost.malasiyanRinggit);
    //     const sdlk = parseInt(this.currencyCost.singaporeDollar);
    //     console.log("All Items", cc, pp, kgc, fc, com, mrlk, sdlk);

    //     //formula= (pp * mrlk) + [(W*kgc/1000) * mrlk ] + (cc* mrlk) + (fc *sdlk) + (com* sdlk)

    //     const calculatedCost =
    //       pp * mrlk +
    //       ((this.weight * kgc) / 1000) * mrlk +
    //       cc * mrlk +
    //       fc * sdlk +
    //       com * sdlk;
    //     console.log("calculatedCost", calculatedCost);

    //     priceCalculationData = {
    //       sNo: i + 1,
    //       name: postage.name,
    //       transit: ssp.transit + "Days",
    //       rate: calculatedCost,
    //       description: postage.description,
    //       img: postage.img,
    //     };
    //     this.Courier.push(priceCalculationData);
    //   }
    //   console.log("this.Courier", this.Courier);
    // },
  },
};
</script>

<style lang="scss" scoped>
// .content {
// margin: auto;
// display: flex;
// width: 100%;
// justify-content: center;
// align-items: center;
// }
.cal {
  margin: 30px;
  height: 100%;
  width: 25%;
  border-radius: 7px;
  justify-content: center;
  flex-direction: column;
  display: flex;

  .calculate {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .calc-form {
    border-radius: 7px;
    padding: 20px;
    background: #fff;
  }

  .btn-cal {
    border-radius: 3px;
    color: white;
    font-size: 0.9rem;
    font-weight: 400;
  }

  .buttons {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .buttons .btn {
    margin: 20px;
  }
}

.table {
  width: 65%;
  height: 100%;
  margin: 20px;

  .v-data-table {
    border-radius: 15px;
  }
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
}

// Media queries
@media (max-width: 762px) {
  .content {
    flex-direction: column;
  }

  .cal,
  .table {
    width: 90%;
  }

  .header {
    display: flex;
  }

  .buttons {
    display: flex;
  }
}
</style>
